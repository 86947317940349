// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-all-developer-activity-page-js": () => import("./../../../src/templates/all-developer-activity-page.js" /* webpackChunkName: "component---src-templates-all-developer-activity-page-js" */),
  "component---src-templates-alternatives-page-js": () => import("./../../../src/templates/alternatives-page.js" /* webpackChunkName: "component---src-templates-alternatives-page-js" */),
  "component---src-templates-developer-activity-page-js": () => import("./../../../src/templates/developer-activity-page.js" /* webpackChunkName: "component---src-templates-developer-activity-page-js" */),
  "component---src-templates-harware-wallets-page-js": () => import("./../../../src/templates/harware-wallets-page.js" /* webpackChunkName: "component---src-templates-harware-wallets-page-js" */),
  "component---src-templates-highest-rates-page-js": () => import("./../../../src/templates/highest-rates-page.js" /* webpackChunkName: "component---src-templates-highest-rates-page-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pages-tag-js": () => import("./../../../src/templates/pages-tag.js" /* webpackChunkName: "component---src-templates-pages-tag-js" */),
  "component---src-templates-platform-rates-page-js": () => import("./../../../src/templates/platform-rates-page.js" /* webpackChunkName: "component---src-templates-platform-rates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-referral-code-page-js": () => import("./../../../src/templates/referral-code-page.js" /* webpackChunkName: "component---src-templates-referral-code-page-js" */),
  "component---src-templates-versus-page-js": () => import("./../../../src/templates/versus-page.js" /* webpackChunkName: "component---src-templates-versus-page-js" */),
  "component---src-templates-withdrawal-page-js": () => import("./../../../src/templates/withdrawal-page.js" /* webpackChunkName: "component---src-templates-withdrawal-page-js" */)
}

