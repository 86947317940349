module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YBB1ZH9ZGD"]},
    },{
      plugin: require('../node_modules/@devular/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"cefirates.com","proxyScript":"/alabama/js/script.js","proxyApi":"/alabama/api/event"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
